import axios, {Axios, AxiosResponse} from "axios";
import {url as apiurl} from "../auth/constants.dev";
import {CombinedGroup} from "../models/CombinedGroup";
import {RemoveDeviceResponse} from "../models/RemoveDeviceResponse";

/**
 * Groups service used to interact with the API groups endpoint.
 *
 * @class Groups
 */
export class Groups {
    private baseURL = apiurl + "/api/v1/Group";

    /**
     * Get groups from the API.
     *
     * @param {string} token - The token used to authenticate the request.
     * @returns {Promise<CombinedGroup[]>} the groups from the API.
     */
    public async getGroups(token: string): Promise<CombinedGroup[]> {
        const response = await axios.get(`${this.baseURL}/combined`, {headers: {Authorization: `Bearer ${token}`}});
        return response.data;
    }

    /**
     * Get groups from the API that a device is a member of.
     *
     * @param {string} token - The token used to authenticate the request.
     * @param {string | number} deviceId - The id of the device to get groups for.
     * @returns {Promise<CombinedGroup[]>} - the groups from the API that the device is a member of.
     */
    public async getDeviceGroups(token: string, deviceId: string | number): Promise<CombinedGroup[]> {
        const response = await axios.get(`${this.baseURL}/Device/${deviceId}`, {headers: {Authorization: `Bearer ${token}`}});
        if (response.data.length <= 0) {
            return [];
        }
        return response.data;
    }

    /**
     * Adds devices in selectedDevice array to all groups in selectedGroupIds array.
     *
     * @param {string} token - The token used to authenticate the request.
     * @param {string} selectedGroupIds - The ids of the groups to add the devices to.
     * @param {string} selectedDevice - The ids of the devices to add to the groups.
     * @returns {Promise<number[]>} - The status codes of the requests.
     */
    public async addDevicesToGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<number[]> {
        const responseArray: number[] = [];
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            for (const groupId of selectedGroupIds) {
                const url = `${this.baseURL}/${groupId}`;
                try {
                    const response = await axios.patch(url, selectedDevice, {headers: {Authorization: `Bearer ${token}`}});
                    responseArray.push(response.status);
                    console.log(`${response.status}: Selected devices have been added to ${groupId} group.`);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        responseArray.push(error.response.status);
                        console.warn(`${error.response.status}: Failed to add selected devices to ${groupId} group.`);
                    }
                }
            }
        }
        return responseArray;
    }

    /**
     * Deletes devices in selectedDevice array from all groups in selectedGroupIds array.
     *
     * @param {string} token - The token used to authenticate the request.
     * @param {string} selectedGroupIds - The ids of the groups to remove the devices from.
     * @param {string} selectedDevice - The ids of the devices to remove from the groups.
     * @returns {Promise<number[]>} - The status codes of the requests.
     */
    public async deleteDevicesFromGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<RemoveDeviceResponse[]> {
        const responseArray: RemoveDeviceResponse[] = [];
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            const promises = selectedGroupIds.map(async (groupId) => {
                const url = `${this.baseURL}/${groupId}`;
                    const response = await axios.delete(url, {
                        data: selectedDevice,
                        headers: {Authorization: `Bearer ${token}`}
                    });
                    return response.data;
            });

            const results = await Promise.all(promises);
            responseArray.push(...results.flat());
        }
        return responseArray;
    }
}