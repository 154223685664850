import axios from "axios";
import { Device } from "../models/Device";
import { url as apiurl } from "../auth/constants.dev";

/**
 * Devices service used to interact with the API devices endpoint.
 *
 * @class Devices
 */
export class Devices {

    /**
     * Get devices from the API.
     *
     * @param {string} token - The token used to authenticate the request.
     * @param {string} filter - The filter to apply to the request.
     * @param {string} next - The next link to use for pagination.
     * @returns {Promise<{ data: Device[], odataLink: string }>} the devices from the API and the next page token.
     */
    public async getDevices(token: string, filter?: string, next?: string): Promise<{ data: Device[], odataLink: string }> {
        let url = apiurl + "/api/v1/Device";
        let params = [];
        if (filter) {
            params.push(`filter=${filter}`);
        }
        if (next) {
            params.push(`next=${next}`);
        }
        if (params.length > 0) {
            url += `?${params.join('&')}`;
        }
        const response = await axios.get(url, {headers: {Authorization: `Bearer ${token}`}});
        return {
            data: response.data.data,
            odataLink: response.data.odataLink
        };
    }
}