import React, {useState} from "react";
import {
    Box,
    Button, ButtonGroup, Checkbox, Flex,
    IconButton,
    Modal,
    Search,
    Spinner,
    Table, Tag,
    Text,
    useDisclosure, useToast
} from "@lego/klik-ui";
import {RefreshBold, StatusHelp, MenuNavigationVertical} from "@lego/klik-ui/icons";
import { useMsal } from "@azure/msal-react";
import { getToken } from "../../auth/authConfig";
import { Groups } from "../../services/Groups";
import { CombinedGroup } from "../../models/CombinedGroup";
import {Device} from "../../models/Device";
import {useTour} from "../../context/TourContext";

/**
 * Props for the DeviceMembershipModal component
 * 
 * @interface DeviceMembershipModalProps
 * @property {Device} device - the device to display software for.
 */
export interface DeviceMembershipModalProps {
    device: Device;
}

/**
 * DeviceMembershipModal component renders a modal that displays the software installed on a device.
 * 
 * @param {DeviceMembershipModalProps} - The props for the DeviceMembershipModal component.
 * @returns {JSX.Element} the rendered DeviceMembershipModal component.
 */
export const DeviceMembershipModal = ({ device }: DeviceMembershipModalProps): JSX.Element => {
    const { instance } = useMsal();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [deviceSoftware, setDeviceSoftware] = React.useState<CombinedGroup[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [softwareState, setSoftwareState] = useState<Map<string, boolean>>(new Map<string, boolean>());
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const {startTour} = useTour()
    
    const toast = useToast();


    
    const filteredGroups = deviceSoftware ? deviceSoftware.filter(group => 
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) || softwareState.get(group.name)
    ) : [];
    
    const getData = () => {
        getToken(instance).then(token => {
            setIsLoading(true);
            const appService = new Groups();
            appService.getDeviceGroups(token.accessToken, device.id).then((groups: CombinedGroup[]) => {
                setDeviceSoftware(groups);
                setIsLoading(false);
                setDataLoaded(true);
            });
        });
    };
    
    /**
     * Handles opening the modal and fetches the software installed on the device.
     */
    const handleOpen = () => {
        onOpen();
        if (!dataLoaded) {
            getData();
        }
        resetSoftwareState();
    };
    
    const resetSoftwareState = () => {
        const newState = new Map<string, boolean>();
        deviceSoftware.forEach(group => {
            newState.set(group.name, false);
        });
        setSoftwareState(newState);
    }
    
    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const newState = new Map(softwareState);
        newState.set(name, checked);
        setSoftwareState(newState);
    }
    
    const handleClickRefresh = () => {
        setIsLoading(true);
        setDataLoaded(false);
        getData();
        resetSoftwareState();
    };
    
    const handleSave = () => {
      const softwareRemove: string[] = [];
      const newState = new Map(softwareState);
      softwareState.forEach((value, key) => {
          
          if (value){
              const group = deviceSoftware.find(group => group.name === key);
              if (group){
                  if (group.availableId){
                      softwareRemove.push(group.availableId);
                  }
                  if (group.requiredId) {
                      softwareRemove.push(group.requiredId);
                  }
                  newState.delete(group.name);
              }
          }
      });
      if (softwareRemove.length > 0) {
          getToken(instance).then(token => {
              let successCount = 0;
              const appService = new Groups();
              appService.deleteDevicesFromGroups(token.accessToken, softwareRemove, [device.id]).then(response => {
                  response.forEach(removeResponse => {
                      if (removeResponse.responseCode === 204) {
                          successCount++;
                      }else if (removeResponse.responseCode === 404){
                          console.log("Error use toast here");
                      }else{
                          console.log("Error use toast here");
                      }
                  });
                  if (successCount > 0){
                      successfulRemove(newState, softwareRemove);
                  }
              });
          })
      }
    };


    const successfulRemove = (newState: Map<string, boolean>, softwareRemove: string[]) => {
        setSoftwareState(newState);
        setDeviceSoftware(prevDeviceSoftware => prevDeviceSoftware.filter(group => !softwareRemove.includes(group.availableId) && !softwareRemove.includes(group.requiredId)));
    };
    

    return (
        <Box >
            <IconButton  size="sm" aria-label={"device-membership-button"} onClick={handleOpen} icon={<MenuNavigationVertical />} />
            <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Header>
                        <Flex justifyContent={"space-between"}>
                            <Text aria-label={"modal-device-name"}>Software Installed on {device.displayName}</Text>
                            <IconButton onClick={startTour} isDisabled={isLoading} alignSelf={"right"} aria-label={"info-button"} icon={<StatusHelp />} variant={"ghost"} />
                        </Flex>
                    </Modal.Header>
                   <Modal.Body>
                       <Box mb={4}>
                           <Flex aria-label={"modal-software-search"} alignItems="center">
                               <Search
                                   onChange={(value: string) => {
                                       setSearchTerm(value);
                                   }}
                                   onEnterPressed={(value: string) => {
                                       setSearchTerm(value);
                                   }}
                                   size="md" value={searchTerm} isDisabled={isLoading} placeholder="Search for a software"
                                   width="90%"
                               />
                               <IconButton size="sm" onClick={handleClickRefresh} aria-label={"modal-refresh-button"} icon={<RefreshBold />} variant={"ghost"} ml={2} />
                           </Flex>
                       </Box>
                        {isLoading ? (
                            <Box p={4}>
                                <Spinner color="light-blue.600" mx="auto" />
                                <Text as="h4" textStyle="xl" color="light-blue.600" fontWeight="bold" textAlign="center" my={2}>Loading device software...</Text>
                            </Box>
                            
                        ) : deviceSoftware.length > 0 ? (
                            <Table aria-label={"modal-software-list"}>
                                <Table.Head>
                                    <Table.Row fontWeight="bold" borderBottomWidth={5} borderColor="light-blue.600" backgroundColor="light-blue.100">
                                        <Table.Cell aria-label={"modal-software-name-header"}>
                                            Software Name
                                        </Table.Cell>
                                        <Table.Cell aria-label={"modal-license-header"}>
                                            License
                                        </Table.Cell>
                                        <Table.Cell aria-label={"modal-remove-header"} textAlign="right">
                                            Remove Software
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Head>
                                <Table.Body>
                                    {filteredGroups.map((group) => (
                                        <Table.Row aria-label={"modal-software-row"} key={group.name} backgroundColor={softwareState.get(group.name)? "#FFF2F2" : "transparent"}>
                                            <Table.Cell >
                                                {group.name}
                                            </Table.Cell>
                                            <Table.Cell >
                                            {group.license ? <Tag colorScheme="warning" shadow="md" size="sm" mr={1}><Tag.Label>PAID</Tag.Label></Tag> : <Tag colorScheme="success" shadow="md" size="sm" mr={1}><Tag.Label>FREE</Tag.Label></Tag>}
                                            </Table.Cell>
                                            <Table.Cell aria-label={"modal-remove-checkbox"} textAlign="right">
                                                <Checkbox
                                                    name={group.name}
                                                    isChecked={softwareState.get(group.name)}
                                                    onChange={handleClickCheck}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        ) : (
                            <Box p={4}>
                                <Text as="h4" textStyle="xl" color="light-blue.600" fontWeight="bold" textAlign="center" my={2}>NO RESULTS FOUND</Text>
                            </Box>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="outline" onClick={onClose}>Close</Button>
                            <Button aria-label={"modal-save-button"} alignSelf="flex-start" onClick={handleSave}>Save</Button>
                        </ButtonGroup>
                        
                        
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </Box>
    );
};