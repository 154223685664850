import React, { useEffect, useState } from "react";
import {
    Checkbox,
    CheckboxGroup,
    Link,
    Stack,
    Table,
    Tag,
    Text
} from "@lego/klik-ui";
import { CombinedGroup } from "../../models/CombinedGroup";
import { CombinedChecksState } from "../../models/CombinedChecksState";

interface GroupRowProps {
    group: CombinedGroup;
    combinedChecks: CombinedChecksState;
    handleCheckedGroup: (checks: CombinedChecksState, group: CombinedGroup) => void;
    forceUpdate: boolean;
    handleForceUpdate: () => void;
}

export const GroupRow = ({ group, combinedChecks, handleCheckedGroup, forceUpdate, handleForceUpdate }: GroupRowProps) => {
    const [requiredChecked, setRequiredChecked] = useState<boolean>(combinedChecks.required);
    const [addChecked, setAddChecked] = useState<boolean>(combinedChecks.add);
    const [removeChecked, setRemoveChecked] = useState<boolean>(combinedChecks.remove);
    const [color, setColor] = useState<string>(combinedChecks.color || 'transparent');
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false);

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCombinedChecksData(name, checked, (newState) => {
            handleCheckedGroup(newState, group);
        });
    };

    const setCombinedChecksData = (name: string, checked: boolean, callback: (newState: CombinedChecksState) => void) => {
        let newColor = color;
        if (checked) {
            switch (name) {
                case 'required':
                    newColor = '#E8F7E6';
                    break;
                case 'add':
                    newColor = '#E8F7E6';
                    break;
                case 'remove':
                    newColor = '#FFF2F2';
                    break;
            }
        } else {
            newColor = 'transparent';
        }
    
        const newRequiredChecked = name === 'required' ? checked : requiredChecked;
        const newAddChecked = (name === 'add') || (name === 'required') ? checked : addChecked;
        const newRemoveChecked = name === 'remove' ? checked : removeChecked;
    
        const newState: CombinedChecksState = {
            required: newRequiredChecked,
            add: newAddChecked,
            remove: newRemoveChecked,
            color: newColor,
            availableId: group.availableId,
            requiredId: group.requiredId,
        };
    
        // Batch state updates
        setRequiredChecked(newRequiredChecked);
        setAddChecked(newAddChecked);
        setRemoveChecked(newRemoveChecked);
        setColor(newColor);
    
        // Trigger callback with the new state
        callback(newState);
    };

    useEffect(() => {
        if (forceUpdate) {
            setRequiredChecked(combinedChecks.required);
            setAddChecked(combinedChecks.add);
            setRemoveChecked(combinedChecks.remove);
            setColor(combinedChecks.color || 'transparent');
            handleForceUpdate();
        }
        
    }, [combinedChecks]);

    const handleDescriptionClick = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        <Table.Body>
            <Table.Row aria-label={"group-row"} style={{ backgroundColor: color }}>
                <Table.Cell aria-label={"software-name"} w="20%">{group.name}</Table.Cell>
                <Table.Cell w="60%">
                    {group.description ? (
                        <Text display="block" mb={2}>
                            {showFullDescription ? group.description : group.description.split(" ").slice(0, 20).join(" ")}
                            {group.description.split(" ").length > 20 && !showFullDescription && (
                                <Link href="#" isInline={true} ms={2} onClick={handleDescriptionClick} style={{ cursor: 'pointer', fontWeight: 'bold' }}>Show more</Link>
                            )}
                            {showFullDescription && (
                                <Link href="#" isInline={true} ms={2} onClick={handleDescriptionClick} style={{ cursor: 'pointer', fontWeight: 'bold' }}>Show less</Link>
                            )}
                        </Text>
                    ) : (
                        <Text as="i" display="block" mb={2}>(empty)</Text>
                    )}
                    <Tag colorScheme="information" shadow="md" size="sm" mr={1}>
                        <Tag.Label>{group.version ? "v" + group.version : "unknown version"}</Tag.Label>
                    </Tag>
                    {group.license ? (
                        <Tag colorScheme="warning" shadow="md" size="sm" mr={1}>
                            <Tag.Label>PAID</Tag.Label>
                        </Tag>
                    ) : (
                        <Tag colorScheme="success" shadow="md" size="sm" mr={1}>
                            <Tag.Label>FREE</Tag.Label>
                        </Tag>
                    )}
                </Table.Cell>
                <Table.Cell aria-label={"actions"} w="30%" textAlign="left">
                    <CheckboxGroup>
                        <Stack spacing="2">
                            <Checkbox
                                name="required"
                                aria-label={"required"}
                                colorScheme={requiredChecked ? "success" : ""}
                                borderColor={requiredChecked ? "success.400" : ""}
                                isChecked={requiredChecked}
                                isDisabled={removeChecked}
                                display="block"
                                onChange={handleClickCheck}>Install automatically</Checkbox>
                            <Checkbox
                                name="add"
                                aria-label={"add"}
                                colorScheme={addChecked ? "success" : ""}
                                borderColor={addChecked ? "success.400" : ""}
                                isChecked={addChecked}
                                isDisabled={removeChecked || requiredChecked}
                                display="block"
                                onChange={handleClickCheck}>Make available in Company Portal</Checkbox>
                            <Checkbox
                                name="remove"
                                aria-label={"remove"}
                                colorScheme={removeChecked ? "error" : ""}
                                borderColor={removeChecked ? "error" : ""}
                                isChecked={removeChecked}
                                isDisabled={addChecked || requiredChecked}
                                display="block"
                                onChange={handleClickCheck}>Remove from device</Checkbox>
                        </Stack>
                    </CheckboxGroup>
                </Table.Cell>
                { /*
                <Table.Cell aria-label={"required-switch"} w="10%" textAlign="center">
                    <Flex alignItems="center" justifyContent="center" height="100%">
                        <Tooltip aria-label="Available" label="Available" placement="left">
                            <Tag mx="1">
                                <Tag.Label>A</Tag.Label>
                            </Tag>
                        </Tooltip>
                        <Switch
                            name="required"
                            aria-label={"required"}
                            colorScheme="warning"
                            defaultChecked={true}
                            isChecked={requiredChecked}
                            isDisabled={!addChecked}
                            onChange={handleClickCheck}
                        />
                        <Tooltip aria-label="Required" label="Required" placement="right">
                            <Tag mx="1">
                                <Tag.Label>R</Tag.Label>
                            </Tag>
                        </Tooltip>
                    </Flex>
                </Table.Cell>
                <Table.Cell w="7%" textAlign="center">
                    <Flex aria-label={"add-checkbox"} alignItems="center" justifyContent="center" flexDirection="column" height="100%">
                        <Checkbox
                            name="add"
                            aria-label={"add"}
                            colorScheme="success"
                            borderColor="success.400"
                            defaultChecked={false}
                            isChecked={addChecked}
                            isDisabled={removeChecked}
                            display="block"
                            onChange={handleClickCheck}
                        />                        
                        <Text as="i" display="block" ml={1} fontSize="sm" fontWeight="semibold" textTransform="uppercase" color="success.400">Add</Text>
                    </Flex>
                </Table.Cell>
                <Table.Cell w="7%" textAlign="center">
                    <Flex aria-label={"remove-checkbox"} alignItems="center" justifyContent="center" flexDirection="column" height="100%">
                        <Checkbox
                            name="remove"
                            aria-label={"remove"}
                            colorScheme="error"
                            borderColor="error.400"
                            defaultChecked={false}
                            isChecked={removeChecked}
                            isDisabled={addChecked}
                            display="block"
                            onChange={handleClickCheck}
                        />
                        <Text as="i" display="block" ml={1} fontSize="sm" fontWeight="semibold" textTransform="uppercase" color="error.400">Remove</Text>
                    </Flex>
                </Table.Cell>
                */}
            </Table.Row>
        </Table.Body>
    );
};