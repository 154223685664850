import {Placement} from "react-joyride";

export const deviceListTourSteps = [
    {
        target: '[aria-label="devices-column"]',
        content: 'This is the device list.',
        placement: 'right-start' as Placement,
        disableBeacon: true,
    },
    {
        target: '[aria-label="devices-container"]',
        content: 'Here you can see all of the factory devices that are available to be added to a software group.',
    },
    {
        target: '[aria-label="device-name-header"]',
        content: 'The name of the device will be displayed in this column.',
    },
    {
        target: '[aria-label="device-name-checkbox"]',
        content: 'Click here to add the device to the selected devices list.',
    },
    {
        target: '[aria-label="device-membership-button"]',
        content: 'Clicking here will display a popup with the software installed on the selected device.',
    },
    {
        target: '[aria-label="device-row"]',
        content: 'Each device is displayed in its own row.',
    },
    {
        target: '[aria-label="selected-device-count"]',
        content: 'You can see how many devices you have selected here.',
    },
    {
        target: '[aria-label="devices-search"]',
        content: 'Search for a device by name here.',
    },
    {
        target: '[aria-label="devices-reset-selection"]',
        content: 'By clicking on this button you will deselect all selected devices.'
    },
]

export const groupListTourSteps = [
    {
        target: '[aria-label="groups-column"]',
        content: 'This is the software list.',
        placement: 'left-start' as Placement,
        disableBeacon: true,
    },
    {
        target: '[aria-label="groups-list"]',
        content: 'Here you can see all of the software that is available to be added factory devices.',
    },
    {
        target: '[aria-label="software-name-header"]',
        content: 'The name of the software will be displayed in this column.',
    },
    {
        target: '[aria-label="software-description-header"]',
        content: 'This column displays the description and version of the software if they are available. It also shows whether the software is free or requires a paid license.',
    },
    {
        target: '[aria-label="install-method-header"]',
        content: 'You can choose the install method for the software in this column',
    },
    {
        target: '[aria-label="action-header"]',
        content: 'This column contains the buttons to add or remove software from the device. You can add or remove multiple software at a time.',
    },
    {
        target: '[aria-label="actions"]',
        content: 'Select the installation method for the software here. Install automatically: the software will be installed immediately. Make available in Company Portal: the software can be installed from Company Portal. Remove from device: the software will be removed from the device.',
    },
    {
        target: '[aria-label="group-row"]',
        content: 'Software is displayed in its own row.',
    },
    {
        target: '[aria-label="software-search-box"]',
        content: 'You are able to search for specific software using this search bar.',
    },
    {
        target: '[aria-label="selected-groups-count"]',
        content: 'You can see the number of software applications you have selected here.',
    },
    {
        target: '[aria-label="groups-reset-selection"]',
        content: 'Click here if you would like to clear your selection of software.',
    },
    {
        target: '[aria-label="save-changes-button"]',
        content: 'Click here if you would like to save the changes and add/remove the selected software from the selected devices.',
    },
];

export const deviceMembershipModalTourSteps = [
    {
        target: '[aria-label="modal-device-name"]',
        content: 'Here you will see which device you are viewing.',
        placement: 'right-start' as Placement,
        disableBeacon: true,
    },
    {
        target: '[aria-label="modal-software-list"]',
        content: 'Here you will see all the software that the device has installed',
    },
    {
        target: '[aria-label="modal-software-row"]',
        content: 'Individual software is displayed in its own row.',
    },
    {
        target: '[aria-label="modal-software-name-header"]',
        content: 'This column displays the name of the software.',
    },
    {
        target: '[aria-label="modal-license-header"]',
        content: 'This column displays whether the software is free or requires a paid license.',
    },
    {
      target: '[aria-label="modal-remove-header"]',
      content: 'This column contains checkboxes that allow you to select software to remove from the device.',  
    },
    {
        target: '[aria-label="modal-remove-checkbox"]',
        content: 'You can check this box if you want to remove this software from the device.',
    },
    {
      target: '[aria-label="modal-software-search"]',
      content: 'You can search for specific software installed on the device using this search bar.',  
    },
    {
        target: '[aria-label="modal-refresh-button"]',
        content: 'Click here to refresh the software list. Keep in mind that some changes take a moment to update.',
    },
    {
        target: '[aria-label="modal-save-button"]',
        content: 'Clicking save will remove all software that has been checked from the device.',
    },
];

export const menuTourSteps = [
    {
        target: '[aria-label="device-tour-button"]',
        content: 'For more information on how the device list works, click here.',
        placement: 'right-start' as Placement,
        disableBeacon: true,
    },
    {
        target: '[aria-label="group-tour-button"]',
        content: 'For more information on how the software list works, click here.',
    },
    {
        target: '[aria-label="report-button"]',
        content: 'Found an Issue? Click here to report it',
    },
    {
        target: '[aria-label="feedback-button"]',
        content: 'Have feedback? Click here to let us know.',
    }
];