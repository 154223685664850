export const styles = {
    options: {
        arrowColor: '#e8f2fa',
        backgroundColor: '#e8f2fa',
        overlayColor: 'rgba(50, 50, 50, 0.8)',
        zIndex: 99999,
    },
    buttonNext: {
        backgroundColor: '#0f538a', // Next button color
        color: '#fff', // Next button text color
    },
    buttonBack: {
        color: 'light-blue.800', // Back button text color
    },
    buttonSkip: {
        color: 'light-blue.800', // Skip button text color
    },
}

export const locale = {
    back: 'Back',
    close: 'Close',
    last: 'Done',
    next: 'Next',
    skip: 'Skip',
}