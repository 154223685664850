import {Device} from "../../models/Device";
import {Checkbox, Table} from "@lego/klik-ui";
import {DevicesLaptop} from "@lego/klik-ui/icons";
import React from "react";
import {DeviceMembershipModal} from "../modals/DeviceMembershipModal";
import {TourProvider} from "../../context/TourContext";
import {deviceMembershipModalTourSteps} from "../../joyride/tourConfig";

/**
 * Props for the DeviceRow component
 * 
 * @interface DeviceRowProps
 * @property {Device} device - The device that corresponds to the row.
 * @property {function} handleSelectedDevices - function to handle when device is selected or deselected.
 * @property {boolean} selected - boolean value indicating if the device is selected.
 */
interface DeviceRowProps {
    device: Device;
    handleSelectedDevices: (event: React.ChangeEvent<HTMLInputElement>, newSelectedDevice: Device) => void;
    selected: boolean
}

/**
 * DeviceRow component renders a row in the device list table.
 * 
 * @param {DeviceRowProps} - The props for the DeviceRow component.
 * @returns {JSX.Element} - The rendered DeviceRow component.
 */
export const DeviceRow = ({ device, handleSelectedDevices, selected }: DeviceRowProps): JSX.Element => {
    const color = selected ? "#E8F7E6" : "transparent";
    const hoverColor = selected ? "#FFF2F2" : "information.50";
    
    return(
        <Table.Row
            aria-label={"device-row"}
            key={device.id}
            backgroundColor={color}
            sx={{
                "&:hover": {
                    backgroundColor: hoverColor
                }
            }}
        >
            <Table.Cell aria-label={"device-name-checkbox"} w="70%" h={"100%"}>
                <Checkbox w="100%" h="%100" key={device.id} value={device.id} isChecked={selected}
                          onChange={(event => {handleSelectedDevices(event,device)})}>
                    <DevicesLaptop /> {device.displayName}
                </Checkbox>
            </Table.Cell>
            <Table.Cell w="30%" textAlign="right">
                <TourProvider steps={deviceMembershipModalTourSteps}>
                    <DeviceMembershipModal device={device}/>
                </TourProvider>
            </Table.Cell>
        </Table.Row>
    );
};