// src/context/TourContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { styles, locale } from '../joyride/joyrideStyles';

/**
 * Props for the TourProvider component.
 * 
 * @interface TourProviderProps
 * @property startTour - function to start the tour.
 */
interface TourContextProps {
    startTour: () => void;
}

/**
 * Props for the TourProvider component.
 * 
 * @interface TourProviderProps
 * @property children - the children to render.
 * @property steps - the steps for the tour.
 */
interface TourProviderProps {
    children: React.ReactNode;
    steps: Step[];
}


const TourContext = createContext<TourContextProps | undefined>(undefined);

export const TourProvider: React.FC<TourProviderProps> = ({ children, steps }) => {
    const [run, setRun] = useState(false);

    const startTour = useCallback(() => {
        setRun(true);
    }, []);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses = ['finished', 'skipped'];
        if (finishedStatuses.includes(status)) {
            setRun(false);
        }
    };

    return (
        <TourContext.Provider value={{ startTour }}>
            {children}
            <Joyride
                steps={steps}
                run={run}
                continuous
                showProgress
                showSkipButton
                callback={handleJoyrideCallback}
                scrollToFirstStep
                disableScrolling
                styles={styles}
                locale={locale}
                hideCloseButton
                disableOverlayClose
            />
        </TourContext.Provider>
    );
};

export const useTour = (): TourContextProps => {
    const context = useContext(TourContext);
    if (!context) {
        throw new Error('useTour must be used within a TourProvider');
    }
    return context;
};