import {useMsal} from "@azure/msal-react";
import {Box, Button, Modal, Text, useDisclosure} from "@lego/klik-ui";
import {AnnouncementBold} from "@lego/klik-ui/icons";
import React from "react";


export const FeedbackModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const initialFocusRef = React.useRef(null);
    const finalFocusRef = React.useRef(null);
    
    const feedbackRedirect = () => {
        window.open("https://forms.office.com/e/93QZjVrrb2", "_blank")
    };
    
    return(
        <Box textAlign="right">
            <Button
                aria-label={"feedback-button"}
                onClick={onOpen}
                rightIcon ={<AnnouncementBold />}
                backgroundColor={"success.500"}
                sx={{
                    '&:hover': {
                        backgroundColor: "success.600"
                    },
                }}
                >
                Give Feedback
            </Button>
            <Modal
                finalFocusRef={finalFocusRef}
                initialFocusRef={initialFocusRef}
                size="2xl"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Modal.Overlay/>
                <Modal.Content>
                    <Modal.Header textAlign="center">Let Us Know How We're Doing!</Modal.Header>
                    <Modal.CloseButton />
                    <Modal.Body display="flex" flexDirection="column" alignItems="center">
                        <Text textAlign={"center"} marginBottom={"10px"}>
                            We're always looking to improve our services. Please let us know how we're doing! 🥳 It will only take a minute 🥳
                        </Text>
                        <Button
                            onClick={feedbackRedirect}
                        >
                            Take Our Survey
                        </Button>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </Box>
    )
};