import React, {useEffect, useState} from "react";
import { getToken } from "../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { Groups } from "../services/Groups";
import {
    Box,
    Button,
    CloseButton,
    Flex,
    Grid,
    IconButton,
    InlineNotification,
    Popover,
    Search,
    Spinner,
    Table,
    Text,
    useToast
} from "@lego/klik-ui";
import {StatusHelp} from '@lego/klik-ui/icons';
import {CombinedGroup} from "../models/CombinedGroup";
import {CombinedChecksState} from "../models/CombinedChecksState";
import {GroupRow} from "../components/rows/GroupRow";
import {Device} from "../models/Device";
import {useTour} from "../context/TourContext";

/**
 * Props for GroupList component
 *
 * @interface GroupListProps
 * @property {any} selectedDevice - An array of device ids that are selected.
 */
interface GroupListProps {
    selectedDevice: Device[];
}

/**
 * GroupList component renders a list of combined software groups that the selected device can be added to or removed from.
 *
 * @param {GroupListProps} - The props for the GroupList component.
 * @returns {JSX.Element} - The rendered GroupList component.
 */
export const GroupList: React.FC<GroupListProps> = ({selectedDevice}: GroupListProps): JSX.Element => {

    const { instance } = useMsal();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [searchGroup, setSearchGroup] = useState<string>("");
    const [combinedGroups, setCombinedGroups] = useState<CombinedGroup[]>([]);
    const [combinedChecksMap, setCombinedChecksMap] = useState<Map<string, CombinedChecksState>>(new Map());
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [selectedGroupsCount, setSelectedGroupsCount] = useState<number>(0);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const {startTour} = useTour();

    /**
     * Switches the forceUpdate state to trigger a re-render of the component.
     */
    const handleForceUpdate = () => {
        setForceUpdate(!forceUpdate);
    }

    const handleResetSelection = () => {
      setOpenPopover(true);
      resetSelection();
      setTimeout(() => {setOpenPopover(false)}, 3000);
    };
    
    /**
     * Handles the checked group and updates the combinedChecksMap state.
     *
     * @param {CombinedChecksState} checks - the values for required, add, remove, and color of the row.
     * @param {CombinedGroup} group - the group that corresponds to the row.
     * @returns {void}
     */
    const handleCheckedGroup = (checks: CombinedChecksState, group: CombinedGroup): void =>{

        setCombinedChecksMap(prevState => {
            const newState = new Map(prevState);
            newState.set(group.name, {
                required: checks.required,
                add: checks.add,
                remove: checks.remove,
                color: checks.color,
                availableId: group.availableId,
                requiredId: group.requiredId
            });
            return newState;
        });

    };

    /**
     * Resets the selection of the groups to an empty Map then forces re-rendering.
     */
    const resetSelection =  () => {
        setCombinedChecksMap(new Map());
        setSelectedGroupsCount(0);
        handleForceUpdate();
    };

    /**
     * Filters the groups based on the search input and the combinedChecksMap to display the search result and the
     * groups that are already checked.
     */
    const filteredGroups = combinedGroups ? combinedGroups.filter(group => {
        const checks = combinedChecksMap.get(group.name);
        return group.name.toLowerCase().includes(searchGroup.toLowerCase()) || (checks && (checks.add || checks.required || checks.remove));
    }) : [];

    /**
     * Splits combinedChecksMap into two arrays, one for groups to add and one for groups to remove.
     * Then adds the devices to the groups to add and removes the devices from the groups to remove.
     */
    const deviceAddOrDelete = async () => {
        const groupsToAdd: string[] = [];
        const groupsToRemove: string[] = [];

        combinedChecksMap.forEach((entry) => {
            if (entry.add) {
                groupsToAdd.push(entry.availableId!);
                if (entry.required !== false) {
                    groupsToAdd.push(entry.requiredId!);
                }
            } else if (entry.remove) {
                groupsToRemove.push(entry.availableId!);
                groupsToRemove.push(entry.requiredId!);
            }
        });

        await addDevicesToGroups(groupsToAdd);
        await deleteDevicesFromGroups(groupsToRemove);
        resetSelection();
    };

    /**
     * Adds the selected devices to the provided array of groups.
     * Displays toast message based on the response from the API.
     *
     * @param {string[]} groupIds - An array of group ids to add the devices to.
     * @returns {boolean} - True if the devices were successfully added, false otherwise.
     */
    const addDevicesToGroups = async (preFilteredIds: string[]): Promise<boolean> => {
        try {
            const token = await getToken(instance);
            const appService = new Groups();

            const invalidGroupIds: string[] = [];
            combinedChecksMap.forEach((entry, groupName) => {
                if (entry.availableId === null || entry.requiredId === null) {
                    toastMessage('error', 'Invalid Group ID', `${groupName} is missing an Available or Required group. Please report a bug so we can address this issue`);
                    if (entry.availableId) invalidGroupIds.push(entry.availableId);
                    if (entry.requiredId) invalidGroupIds.push(entry.requiredId);
                }
            });
            
            const groupIds = preFilteredIds.filter(groupId => groupId !== null && !invalidGroupIds.includes(groupId));
            
            if (groupIds.length === 0) {
                console.log('No groups selected.');
                return false;
            } else {
                const responses = (await Promise.all(groupIds.map(groupId =>
                    appService.addDevicesToGroups(token.accessToken!, [groupId.toString()], selectedDevice.map(device => device.id))))).flat();
                if (responses.includes(200)) {
                    toastMessage('success', `${selectedDevice.length === 1? 'Device' : 'Devices'} Added`, `${selectedDevice.length === 1? 'Device has been successfully ' : 'All devices have been successfully'} added.`);
                    return true;
                }else if (responses.includes(400)) {
                    toastMessage('warning', `Unable to Add ${selectedDevice.length === 1? 'Device' : 'Devices'}`, `The selected ${selectedDevice.length === 1? 'device is' : 'devices are'} already a member of the group.`);
                    return false;
                }else{
                    toastMessage('error', 'Error', `An error occurred while adding the ${selectedDevice.length === 1? 'device' : 'devices'} to the group.`)
                    return false;
                }
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    /**
     * Deletes the selected devices from the provided array of groups.
     * Displays toast message based on the response from the API.
     *
     * @param {string[]} groupIds - An array of group ids to remove the devices from.
     */
    const deleteDevicesFromGroups = async (groupIds: string[]) => {
        try {
            let successCount = 0;
            const token = await getToken(instance);
            const appService = new Groups();
            if (groupIds.length === 0) {
                console.log('No groups selected.');
                return false;
            } else {
                const responses = await Promise.all(groupIds.map(groupId =>
                    appService.deleteDevicesFromGroups(token.accessToken!, [groupId.toString()], selectedDevice.map(device => device.id))
                ));
                const flattenedResponses = responses.flat();
                flattenedResponses.forEach(response => {
                    const groupName = combinedGroups.find(group => group.availableId === response.groupId || group.requiredId === response.groupId)?.name;
                    const deviceName = selectedDevice.find(device => device.id === response.deviceId)?.displayName;
                    if (response.responseCode === 204) {
                        successCount++;
                    } else if (response.responseCode === 404) {
                        console.log(`Warning: Unable to Remove ${deviceName} from ${groupName}`);
                        toastMessage('warning', `Unable to Remove ${deviceName}`, `${deviceName} is not a member of ${groupName}.`);
                    }
                });
                if (successCount > 0) {
                    console.log(`Success: ${successCount} devices removed`);
                    toastMessage('success', `${successCount === 1 ? 'Device' : 'Devices'} Removed`, `${successCount === 1 ? 'Device has been successfully ' : 'All devices have been successfully'} removed.`);
                }
            }
        } catch (error) {
            toastMessage('error', 'Error', error instanceof Error ? error.message : String(error));
            return false;
        }
    };

    /**
     * Displays a toast message with the provided color, title, and description.
     *
     * @param {string} toastColor - The color for the toast message box.
     * @param {string} toastTitle - The title of the toast message.
     * @param {string} toastDescription - the description of the toast message.
     */
    const toastMessage = (toastColor: string, toastTitle: string,  toastDescription: string) => {
        toast({
            position: 'bottom-right',
            duration: 10000,
            render: ({ onClose }) => (
                <InlineNotification variant={toastColor}>
                    <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                        <InlineNotification.Title>{toastTitle}</InlineNotification.Title>
                        <InlineNotification.Description>
                            {toastDescription}
                        </InlineNotification.Description>
                    </InlineNotification.Content>
                    <CloseButton aria-label="Close" onClick={onClose} />
                </InlineNotification>
            ),
        });
    };

    /**
     * Completes the tasks of adding and removing devices from the selected groups.
     * Resets the selection of the groups after adding or removing.
     */
    const completeTasks = async () => {
        await deviceAddOrDelete();
        resetSelection();
    };

    /**
     * Fetches the groups from the API and sets the combinedGroups state.
     */
    useEffect(() => {
        getToken(instance).then(token => {
            const appService = new Groups();
            appService.getGroups(token.accessToken!).then(group => {
                setCombinedGroups(group)
                setIsLoading(false);
            })
        })
    }, [instance])
    
    

    /**
     * Updates the selectedGroupsCount state based on the combinedChecksMap.
     * Used for displaying how many groups are selected.
     */
    useEffect(() => {
        let count = 0;
        if (combinedChecksMap.size === 0){
            setSelectedGroupsCount(0)
        }
        else{
            combinedChecksMap.forEach((value) => {
                if (value.add || value.required || value.remove) {
                    count++;
                }
            })
        }
        setSelectedGroupsCount(count);
    }, [selectedGroupsCount, combinedChecksMap]);

    return (
        <Flex aria-label={"groups-column"} flexDirection="column" bg="light-blue.300" p={3} h={"100%"}>
            <Grid alignItems="center" templateColumns="repeat(2, 1fr)" mb={3}>
                <Box mb={3}>
                    <Text as="h2" textStyle="2xl" color="white">Software List</Text>
                </Box>
                <Box textAlign="right">
                    <IconButton onClick={startTour} alignSelf={"right"}  aria-label={"group-tour-button"} icon={<StatusHelp />} variant={"plain"} backgroundColor={"light-blue.300"} shadow={"none"} _hover={{backgroundColor:"light-blue.300"}}  />
                    <Button
                        aria-label={"save-changes-button"}
                        className="savechanges"
                        size="sm"
                        mx={1}
                        bg="success.400"
                        fontWeight={700}
                        borderRadius={0}
                        isDisabled={selectedDevice.length === 0 || selectedGroupsCount === 0}
                        onClick={async () => {
                            try {
                                await completeTasks();
                            } catch (error) {
                                console.error("An error occurred:", error);
                            }
                        }}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Grid>
            <Box aria-label={"software-search-box"} mb={4}>
                <Search
                    aria-label={"group-search"}
                    onChange={(value: string) => {
                        setSearchGroup(value);
                    }}
                    onEnterPressed={(value: string) => {
                        setSearchGroup(value);
                    }}
                    size="md" value={searchGroup} isDisabled={isLoading} placeholder="Search for a software"
                />
            </Box>
            <Flex flexDirection="column" mb={3}>
                <Grid alignItems="center" templateColumns="repeat(2, 1fr)">
                    <Box>
                        <Text aria-label={"selected-groups-count"} as="h4" textStyle="lg" color="white">
                            {selectedGroupsCount} {selectedGroupsCount === 1 ? 'software application' : 'software applications'} selected.
                        </Text>
                    </Box>
                    <Box textAlign="right">
                        <Popover isOpen={openPopover} onClose={() => setOpenPopover(false)} placement={"left-start"}>
                            <Popover.Trigger>
                                <Button
                                    aria-label={"groups-reset-selection"}
                                    variant="outline"
                                    size="sm"
                                    color="white"
                                    borderColor="white"
                                    borderRadius="0"
                                    onClick={() => {
                                        handleResetSelection();
                                    }}
                                    isDisabled={selectedGroupsCount === 0}
                                >
                                    Clear Selection
                                </Button>
                            </Popover.Trigger>
                            <Popover.Content alignItems={"flex-start"}>
                                <Popover.Arrow/>
                                <Popover.CloseButton/>
                                <Popover.Header>Software Selection</Popover.Header>
                                <Popover.Body>Software selection has been reset.</Popover.Body>
                            </Popover.Content>
                        </Popover>
                        
                    </Box>
                </Grid>
            </Flex>

            {isLoading ? (
                <Box p={4}>
                    <Spinner color="white" mx="auto" />
                    <Text as="h4" textStyle="xl" color="white" fontWeight="bold" textAlign="center" my={2}>Loading groups...</Text>
                </Box>
            ) : (
                <Flex aria-label={"groups-list"} direction="column" p="0">
                    <Table bg="white">
                        <Table.Head>
                            <Table.Row fontWeight="bold" borderBottomWidth={1} borderColor="light-blue.300">
                                <Table.Cell aria-label={"software-name-header"} w="20%">Software Name</Table.Cell>
                                <Table.Cell aria-label={"software-description-header"} w="60%">Description</Table.Cell>
                                <Table.Cell aria-label={"action-header"} colSpan={2} w="30%">Actions</Table.Cell>
                            </Table.Row>
                        </Table.Head>
                    </Table>
                    <div className={"scroll-container"}>
                        <Table bg="white">
                            {filteredGroups
                                .sort((a, b) => {
                                    return a.name.localeCompare(b.name);
                                })
                                .map(group => {
                                    const combinedChecks = combinedChecksMap.get(group.name) || {
                                        required: false,
                                        add: false,
                                        remove: false,
                                        color: 'transparent',
                                        availableId: group.availableId,
                                        requiredId: group.requiredId
                                    };

                                    return (
                                        <GroupRow
                                            key={group.name}
                                            group={group}
                                            combinedChecks={combinedChecks}
                                            handleCheckedGroup={handleCheckedGroup}
                                            forceUpdate={forceUpdate}
                                            handleForceUpdate={handleForceUpdate}
                                        />
                                    );
                                })}
                        </Table>
                    </div>
                </Flex>
            )}
        </Flex>
    );
};