import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getRoles, getToken } from "./auth/authConfig";
import {Box, Grid} from "@lego/klik-ui";
import { Menu } from "./components/Menu";
import { DeviceList } from "./pages/DeviceList";
import { GroupList } from "./pages/GroupList";
import {Device} from "./models/Device";
import {TourProvider} from "./context/TourContext";
import {deviceListTourSteps, groupListTourSteps, menuTourSteps} from "./joyride/tourConfig";

function App() {
    const { instance } = useMsal();
    const [roles, setRoles] = useState<string[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<Device[]>([]);

    const handleSelectedDevice = (event: React.ChangeEvent<HTMLInputElement>, device: Device) => {
        if (event.target.checked){
            setSelectedDevice(prev => [...prev, device]);
            console.log(selectedDevice);
        }
        else {
            setSelectedDevice(prev => prev.filter(selected => selected.id !== device.id));
            console.log(selectedDevice);
        }
    };


    const resetDeviceSelection = () => {
        setSelectedDevice([]);
    };

    useEffect(() => {
        getToken(instance).then(token => {
            setRoles(getRoles(token.accessToken));
        });
    }, [instance]);

    return (
        <>
            <TourProvider steps={menuTourSteps}>
                <Menu />
            </TourProvider>
                <Grid className="main-content" templateColumns="1fr 3fr">
                    <Box>
                        <TourProvider steps={deviceListTourSteps}>
                            <DeviceList selectedDevice={selectedDevice} handleSelectedDevices={handleSelectedDevice}
                                        resetDeviceSelection={resetDeviceSelection}/>
                        </TourProvider>
                    </Box>
                    <Box>
                        <TourProvider steps={groupListTourSteps}>
                            <GroupList selectedDevice={selectedDevice} />
                        </TourProvider>
                    </Box>
                    
                </Grid>
            
        </>
    );
}

export default App;